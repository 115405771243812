import cx from 'classnames';
import React, { useEffect, useReducer, useState } from 'react';
import { AddReviewForm } from '../components/add-review-form-modal';
import { Breadcrumbs, BreadcrumbsItem } from '../components/breadcrumbs';
import { Container } from '../components/container';
import { Review } from '../components/review';
import { homepageTabsReducer, initialState } from "../contexts/HomepageTabsContext";

const ReviewsPage = () => {
  const [state, dispatch] = useReducer(homepageTabsReducer, initialState)
  useEffect(() => {
    dispatch({ type: "OPEN_TAB2" })
  }, [])


  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState("")
  const webVersions = ['new-calc-A', 'new-calc-B']

  useEffect(() => {
    const curVersion = localStorage.getItem("website-version")
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
    const randomVersion = webVersions[randomIndex]
    if (!curVersion || curVersion === undefined || !webVersions.includes(curVersion)) {
      localStorage.setItem("website-version", randomVersion)
      setWebsiteVersion(randomVersion)
    } else {
      setWebsiteVersion(curVersion)
    }
  }, [])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        pageURL: "/reviews",
        testovani: websiteVersion,
        event: "ga.pageview"
      })
  }, [websiteVersion])

  return (
    <Container>
      <Breadcrumbs>
        <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
        <BreadcrumbsItem link="/reviews">Recenze</BreadcrumbsItem>
      </Breadcrumbs>

      <h3 className="fancyUnderlineText sectionTitleNew text-center">
        Všechny <span>recenze</span>
      </h3>
      <AddReviewForm />
      {/* <CategoriesFilterPanel /> */}
      <Review />
    </Container>
  )
};

export default ReviewsPage;