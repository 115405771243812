import cx from "classnames"
import { FastField, Form, withFormik } from "formik"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import Select from "react-select"
import MaskedInput from "react-text-mask"
import * as Yup from "yup"
import { useModal } from "../modal2"
import { getCookie, getReferrer, getUTM } from "../../helpers"
import { useLangContext } from "../../utils/lang"
import { Button2 } from "../button2"
import jobFormStyles from "./add-review-form.module.css"
import { CustomCheckbox } from "./custom-checkbox"
import styles from "./form2.module.css"

const FILE_SIZE = 160 * 1024
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phone", "Telefonní číslo musí obsahovat 9 znaků", value =>
    rePhoneNumber.test(value)
  )
})

export const AddReviewFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  btnType = "secondary",
  btnText = "Objednat",
  form_name = "add-review_new",
  setFieldValue,
}) => {
  const [checkTerms2, setCheckTerms2] = useState(false)
  const { show, RenderModal } = useModal()
  const { t } = useTranslation()
  return (
    <Form name={form_name} method="post" className={cx(jobFormStyles.jobForm)}>
      <RenderModal className="modalForm">
        <h2>Osobních údajů</h2>
        <p>
          Souhlasím výslovně se zpracováním a uchováním veškerých mých osobních
          údajů v rozsahu, jakém byly mnou poskytnuty, společností Nutritionpro
          s.r.o., IČO 07752971.
          <br />
          Souhlas lze kdykoliv odvolat, zásady nakládání s osobními údaji a
          další informace na{" "}
          <a href="/gdpr/" target="_blank">
            https://nutritionpro.cz/gdpr/
          </a>
        </p>
      </RenderModal>
      <div className={cx(styles.inputField, jobFormStyles.firstnameInput)}>
        <FastField
          component="input"
          type="text"
          name="firstname"
          className={styles.input}
          placeholder={t("forms.onlineOrderFormNamesLabel")}
        />
        {touched.firstname && errors.firstname && (
          <span className={styles.error}>{errors.firstname}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.programInput)}>
        <Select
          className={cx(styles.input, styles.select)}
          styles={{
            control: (provided, state) => ({
              ...provided,
              height: "100%",
              borderRadius: "40px",
              border: "none",
              paddingLeft: "20px",
              whiteSpace: "nowrap",
            }),
            placeholder: defaultStyles => {
              return {
                ...defaultStyles,
                color: "#d5dbe1",
              }
            },
          }}
          options={[
            {
              value: "5chodové menu",
              label: t("forms.mainFormMenuOption1"),
            },
            {
              value: "3chodové menu",
              label: t("forms.mainFormMenuOption2"),
            },
            {
              value: "2chodové menu",
              label: t("forms.mainFormMenuOption3"),
            },
          ]}
          isSearchable={false}
          value={
            values.program
              ? {
                  value: values.program,
                  label: values.program,
                }
              : null
          }
          onChange={e => {
            setFieldValue("program", e.value)
          }}
          placeholder={"Vyberte svůj program"}
        />
        {touched.program && errors.program && (
          <span className={styles.error}>{errors.program}</span>
        )}
      </div>
      <div className={cx(styles.inputField, jobFormStyles.commentInput)}>
        <FastField
          component="textarea"
          type="text"
          name="comment"
          className={styles.input}
          placeholder={"Vaše zpětná vazba"}
        />
        {touched.comment && errors.comment && (
          <span className={styles.error}>{errors.comment}</span>
        )}
      </div>
      {/* <div className={cx(styles.inputField, jobFormStyles.fileInput)}>
        <FastField
          component="input"
          type="file"
          name="file"
          component="input"
          title="Select a file"
          placeholder="Choose a file"
          className={styles.input}
        />
        {touched.file && errors.file && (
          <span className={styles.error}>{errors.file}</span>
        )}
      </div> */}
      <div className={cx(styles.inputField, jobFormStyles.termsInput)}>
        {/* <input
          id="checkTerms2"
          type="checkbox"
          name="checkTerms2"
          checked={checkTerms2}
          onChange={e => {
            setCheckTerms2(e.target.checked)
          }}
        />
        <label htmlFor="checkTerms2">
          {t("forms.mainFormCheckTerms3Label")}
        </label> */}
        <CustomCheckbox id="checkTerms2" name="checkTerms2">
          <Trans i18nKey="forms.mainFormCheckTerms3Label">
            Souhlasím se zpracováním
            <button
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
              }}
              onClick={show}
            >
              osobních údajů
            </button>
          </Trans>
          {/* Souhlasím se zpracováním{" "}
            <button
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
              }}
              onClick={show}
            >
              osobních údajů
            </button> */}
        </CustomCheckbox>
      </div>
      <div className={cx(jobFormStyles.submitButton)}>
        <Button2
          color="secondary"
          buttonType="submit"
          disabled={isSubmitting || !checkTerms2}
        >
          {btnText || t("forms.contactForm2.CTA")}
        </Button2>
      </div>
    </Form>
  )
}

export const AddReviewFormLayoutForm = withFormik({
  mapPropsToValues: props => ({
    firstname: "",
    program: "",
    comment: "",
    file: "",
    referrer: "",
    ga: "",
    success: false,
    form_name: props.form_name,
  }),
  validationSchema: () =>
    Yup.object().shape({
      firstname: Yup.string()
        .min(2)
        .required(),
      comment: Yup.string()
        .min(2)
        .required(),
      // file: Yup.mixed()
      //   .required("A file is required")
      // .test(
      //   "fileSize",
      //   "File too large",
      //   value => value && value.size <= FILE_SIZE
      // )
      // .test(
      //   "fileFormat",
      //   "Unsupported Format",
      //   value => value && SUPPORTED_FORMATS.includes(value.type)
      // ),
    }),
  handleSubmit: async (
    { firstname, program, comment, file, form_name },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      const isEn = document.location.pathname.includes("/en")

      const getFormName = () => {
        let formName
        if (form_name) {
          formName = isEn ? `${form_name}_en` : form_name
        } else {
          formName = isEn ? `AddReviewForm_en` : `AddReviewForm`
        }
        return formName
      }

      const data = {
        form_name: getFormName(),
        language: isEn ? "en" : "cs",
        region: process.env.GATSBY_COUNTRY,
        branchId: process.env.GATSBY_BRANCH_ID,
        name: firstname,
        program: program,
        comment: comment,
        file: file,
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
      }
      await fetch(`${process.env.GATSBY_APP_API_URL}/application`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      // await console.log("data", data)
      await setSubmitting(false)
      await setFieldValue("success", true)
      setTimeout(() => {
        resetForm()
        window.location.href = isEn ? "/en/thank-you" : "/thank-you"
        window.dataLayer.push({
          event: "ga.pageview",
          pageURL: isEn ? "/en/thank-you-add-review" : "/thank-you-add-review",
          pageType: "Purchase",
        })
      }, 300)
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      alert("Something went wrong, please try again!")
    }
  },
})(AddReviewFormLayout)
